import { ReactNode } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Navbar from "./Navbar";

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          minHeight: "100vh",
          maxWidth: "100vw",
          flexGrow: 1,
        }}
      >
        <Navbar />
        <Container maxWidth="xl" sx={{ marginBottom: 4}}>
          {children}
        </Container>
      </Box>
    </>
  );
};

export default Layout;