import { useState } from "react";
import { MButton } from "../components/MButton";
import MModal from "../components/MModal";
import { Box, LinearProgress, LinearProgressProps, Typography } from "@mui/material";
import NotebookImage from "../images/notebook.png";
import MFileUpload from "../components/MFileUpload";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { completeMultiPartUpload, uploadChunk, uploadToS3Service } from "../utils/fileUploadingAWS";

export function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

interface UploadDMSProps {
    name?: string;
    device: any;
    patientIdUnique: string;
}

const CHUNK_SIZE = 6;

interface FileInterface {
    file?: File;
    fileSize?: number;
    fileName?: string;
    fileType?: string;
}

// Specify the extensions to check for
const allowedExtensions = ['.cdm', '.log'];

const getFileExtension = (filename: string) => {
    return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
};

const combineUploads = (parts: number[]): number => {
    // Ensure all parts are 100%
    if (parts.every(part => part === 100)) {
        return 100; // If all parts are 100%, the combined upload is 100%
    } else {
        // If not all parts are 100%, calculate the average percentage
        const total = parts.reduce((accumulator, part) => accumulator + part, 0);
        const combinedPercentage = total / parts.length;
        return combinedPercentage;
    }
}

export const UploadDMS = (props: UploadDMSProps) => {
    const { name = 'Upload a Recording', device, patientIdUnique } = props;
    const [open, setOpen] = useState(false);
    const [step, setStep] = useState('0');

    const [uploadingProgress, setUploadingProgress] = useState(0);
    const [uploadingEventsProgress, setUploadingEventsProgress] = useState(0);
    const [uploadingLogsProgress, setUploadingLogsProgress] = useState(0);

    const [recordingFileData, setRecordingFileData] = useState<FileInterface | null>(null)
    const [logsFileData, setLogsFileData] = useState<FileInterface | null>(null)
    const [eventsFileData, setEventsFileData] = useState<FileInterface | null>(null)

    const totalLoading = [uploadingProgress, logsFileData && uploadingLogsProgress, eventsFileData && uploadingEventsProgress].filter(Boolean) as number[];
    const totalLoadingProgress = combineUploads(totalLoading);

    const handleRecordingFile = (file?: File) => {
        if (!file) {
            setRecordingFileData(null);
            setEventsFileData(null);
            return;
        }

        setRecordingFileData({
            file,
            fileName: file.name,
            fileSize: (file?.size / (1024 * 1024)),
            fileType: getFileExtension(file?.name)
        })
    }

    const handleLogsFile = (file?: File) => {
        if (!file) {
            setLogsFileData(null);
            return;
        }

        setLogsFileData({
            file,
            fileName: file.name,
            fileSize: (file?.size / (1024 * 1024)),
            fileType: getFileExtension(file?.name)
        })
    }

    const handleEventsDiaryFile = (file?: File) => {
        if (!file) {
            setEventsFileData(null);
            return;
        }

        setEventsFileData({
            file,
            fileName: file.name,
            fileSize: (file?.size / (1024 * 1024)),
            fileType: getFileExtension(file?.name)
        })
    }

    const onClose = () => {
        setStep('0');
        setOpen(false);
        setRecordingFileData(null);
        setLogsFileData(null);
        setEventsFileData(null);
    }

    const startRecordingFileUploading = async () => {
        const key = device.id + "." + recordingFileData!.fileType;

        const { s3, credentials, uploadId } = await uploadToS3Service(key);

        let numberOfParts = Math.ceil(recordingFileData!.fileSize! / CHUNK_SIZE);
        let partsArray = [];
        for (let partNumber = 1; partNumber <= numberOfParts; partNumber++) {
            let uploadedPart = await uploadChunk(s3, credentials, recordingFileData!.file, partNumber, key, uploadId, CHUNK_SIZE);
            partsArray.push(uploadedPart);

            let percent = (100 * partNumber / numberOfParts);
            setUploadingProgress(percent);
        }

        await completeMultiPartUpload(s3, credentials, device.id, key, recordingFileData!.fileType!, uploadId, partsArray, patientIdUnique);
    }

    const startLogsFileUploading = async () => {
        const MEASURMENT_ID = device.id;
        const key = `${MEASURMENT_ID}-device.log`;

        const { s3, credentials, uploadId } = await uploadToS3Service(key);

        let numberOfParts = Math.ceil(logsFileData!.fileSize! / CHUNK_SIZE);
        let partsArray = [];
        for (let partNumber = 1; partNumber <= numberOfParts; partNumber++) {
            let uploadedPart = await uploadChunk(s3, credentials, logsFileData!.file, partNumber, key, uploadId, CHUNK_SIZE);
            partsArray.push(uploadedPart);

            let percent = (100 * partNumber / numberOfParts);
            setUploadingLogsProgress(percent);
        }

        await completeMultiPartUpload(s3, credentials, device.id, key, logsFileData!.fileType!, uploadId, partsArray, patientIdUnique);
    }

    const startEventsFileUploading = async () => {
        const MEASURMENT_ID = device.id;
        const key = `${MEASURMENT_ID}-event-manual.pdf`;

        const { s3, credentials, uploadId } = await uploadToS3Service(key);

        let numberOfParts = Math.ceil(eventsFileData!.fileSize! / CHUNK_SIZE);
        let partsArray = [];
        for (let partNumber = 1; partNumber <= numberOfParts; partNumber++) {
            let uploadedPart = await uploadChunk(s3, credentials, eventsFileData!.file, partNumber, key, uploadId, CHUNK_SIZE);
            partsArray.push(uploadedPart);

            let percent = (100 * partNumber / numberOfParts);
            setUploadingEventsProgress(percent);
        }

        await completeMultiPartUpload(s3, credentials, device.id, key, eventsFileData!.fileType!, uploadId, partsArray, patientIdUnique);
    }

    const startFilesUploading = async () => {
        setStep('1.2');
        await startRecordingFileUploading();
        if (logsFileData) {
            await startLogsFileUploading();
        }
        if (eventsFileData) {
            await startEventsFileUploading();
        }
        if (totalLoadingProgress === 100) {
            setStep('1.3');
        }
    }


    const handleSelectFolder = async () => {
        try {
            // Prompt the user to select a folder
            const directoryHandle = await (window as any).showDirectoryPicker(
                {
                    startIn: 'desktop'
                }
            );

            // Iterate over the files in the selected folder
            const files = [];
            for await (const entry of directoryHandle.values()) {
                if (entry.kind === 'file') {
                    const fileExtension = entry.name.substring(entry.name.lastIndexOf('.')).toLowerCase();
                    if (allowedExtensions.includes(fileExtension)) {
                        files.push(entry.name);
                        const fileHandle = await entry.getFile();

                        // cdm
                        if (entry.name.endsWith(allowedExtensions[0])) {
                            handleRecordingFile(fileHandle)
                        }

                        // log
                        if (entry.name.endsWith(allowedExtensions[1])) {
                            handleLogsFile(fileHandle)
                        }
                    }
                }
            }

            // Check if any files with the allowed extensions were found
            if (files.length === allowedExtensions.length) {
                setStep('1.1');
            } else {
                setStep('2.1');
            }
        } catch (e) {
            console.log('user not selected folder:', e);
        }
    };

    const firstStep = (
        <Box textAlign="center">
            <Typography variant="h3">Upload a Recording</Typography>
            <Box mt={6}>
                <Typography variant="body1">Make sure the device is plugged into the computer.</Typography>
            </Box>
            <Box mt={2}>
                <img style={{ width: '213px', height: '138px' }} src={NotebookImage} alt="Notebook image" />
            </Box>
            <Box mt={2} display="flex" justifyContent="center">
                <MButton
                    variant="outlined"
                    onClick={onClose}
                >
                    Cancel
                </MButton>
                <MButton sx={{ ml: 2 }} onClick={handleSelectFolder}>Start</MButton>
            </Box>
        </Box>
    );

    const modalContent = () => {
        switch (step) {
            case '0':
                return firstStep;
            case '1.1':
                return (
                    <Box textAlign="center">
                        <Typography variant="h3">
                            Recording fetched. <br />
                            Add patient diary?
                        </Typography>
                        <Box mt={6}>
                            <Typography variant="body1"><b>Events diary</b> {eventsFileData ? <>(optional)</> : ''}</Typography>
                            {
                                !eventsFileData ? (
                                    <Box mt={2}>
                                        <Typography variant="body1">(optional, .PDF format)</Typography>
                                    </Box>
                                ) : <></>
                            }
                            <Box mt={2} display="flex" alignItems="center" justifyContent="center">
                                <MFileUpload
                                    id="events-diary-file"
                                    inputProps={{ accept: '.pdf' }}
                                    onFileChangeHandler={f => handleEventsDiaryFile(f)}
                                />
                            </Box>
                        </Box>
                        <Box mt={4} display="flex" justifyContent="center">
                            <MButton
                                variant="outlined"
                                onClick={onClose}
                            >
                                Cancel
                            </MButton>
                            <MButton
                                sx={{ ml: 2 }}
                                onClick={startFilesUploading}
                            >
                                {!eventsFileData ? 'Skip diary' : 'Confirm'}
                            </MButton>
                        </Box>
                    </Box>
                );
            case '1.2':
                return (
                    <Box textAlign="center">
                        <Typography variant="h3">Uploading ...</Typography>
                        <Box mt={8}>
                            <LinearProgressWithLabel value={totalLoadingProgress} />
                        </Box>
                        <Box mt={8}>
                            <MButton variant="outlined" onClick={onClose}>Cancel</MButton>
                        </Box>
                    </Box>
                );
            case '1.3':
                return (
                    <Box textAlign="center">
                        <Typography variant="h3">Complete</Typography>
                        <Box mt={8}>
                            <CheckCircleOutlineIcon sx={{ fontSize: 64 }} />
                            <Typography sx={{ mt: 2 }} variant="body1">Data has been uploaded</Typography>
                        </Box>
                        <Box mt={8}>
                            <MButton onClick={() => {
                                onClose();
                                window.location.reload();
                            }}>
                                Done
                            </MButton>
                        </Box>
                    </Box>
                );
            case '2.1':
                return (
                    <Box textAlign="center">
                        <Typography variant="h3">Recording not found</Typography>
                        <Box mt={6}>Try again selecting the right folder or select files manually</Box>
                        <Box mt={8} display="flex" justifyContent="center">
                            <MButton
                                variant="outlined"
                                onClick={onClose}
                            >
                                Cancel
                            </MButton>
                            <MButton sx={{ ml: 2 }} onClick={handleSelectFolder}>Try again</MButton>
                            <MButton sx={{ ml: 2 }} onClick={() => setStep('2.2')}>Select files manually</MButton>
                        </Box>
                    </Box>
                );
            case '2.2':
                return (
                    <Box textAlign="center">
                        <Typography variant="h3">Select files manually</Typography>
                        <Box mt={6} textAlign="left">
                            <Box display="flex" alignItems="center" mb={3}>
                                <Typography sx={{ width: 150 }} variant="body1">Recording file</Typography>
                                <MFileUpload
                                    id="recording-file"
                                    inputProps={{ accept: '.cdm' }}
                                    onFileChangeHandler={f => handleRecordingFile(f)}
                                />
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Typography sx={{ width: 150 }} variant="body1">Log file</Typography>
                                <MFileUpload
                                    id="log-file"
                                    inputProps={{ accept: '.log' }}
                                    onFileChangeHandler={f => handleLogsFile(f)}
                                />
                            </Box>
                        </Box>
                        <Box mt={8} display="flex" justifyContent="center">
                            <MButton
                                variant="outlined"
                                onClick={() => setOpen(false)}
                            >
                                Cancel
                            </MButton>
                            <MButton
                                disabled={!recordingFileData}
                                sx={{ ml: 2 }}
                                onClick={() => setStep('1.1')}
                            >
                                Confirm
                            </MButton>
                        </Box>
                    </Box>
                );
            default:
                return firstStep;
        }
    }

    return (
        <>
            <MModal
                open={open}
                handleClose={() => setOpen(false)}
                width={600}
                height="auto"
            >
                {modalContent()}
            </MModal>
            <MButton onClick={() => setOpen(true)}>{name}</MButton>
        </>
    );
};

export default UploadDMS;