import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { StudyStatuses, StudyStatusesEnum } from '../api/study';
import { allStudyStatuses } from "../utils/constants";

interface MStatusPlatesProps {
  value: StudyStatuses;
  disableStyling?: boolean
}

export const MStatusPlates = (props: MStatusPlatesProps) => {
  const { value, disableStyling } = props;

  const defaultStyles = {
    paddingX: 1.5,
    paddingY: 1,
    borderRadius: 1.5,
    display: 'inline-flex',
    '& > p': {
      fontWeight: '700'
    }
  }

  const renderValue = () => {
    switch (value) {
      case StudyStatusesEnum.draft:
        return {
          label: allStudyStatuses.find(ss => ss.value === StudyStatusesEnum.draft)?.label,
          sx: {
            ...defaultStyles,
            border: '1px solid #808080',
            color: '#808080'
          }
        };
      case StudyStatusesEnum.inprogress:
        return {
          label: allStudyStatuses.find(ss => ss.value === StudyStatusesEnum.inprogress)?.label,
          sx: {
            ...defaultStyles,
            background: '#F3F5E8'
          }
        };
      case StudyStatusesEnum.in_review:
        return {
          label: allStudyStatuses.find(ss => ss.value === StudyStatusesEnum.in_review)?.label,
          sx: {
            ...defaultStyles,
            background: '#A3D5FF'
          }
        };
      case StudyStatusesEnum.interpretating:
        return {
          label: allStudyStatuses.find(ss => ss.value === StudyStatusesEnum.interpretating)?.label,
          sx: {
            ...defaultStyles,
            background: '#F3F5E8'
          }
        };
      case StudyStatusesEnum.completed:
        return {
          label: allStudyStatuses.find(ss => ss.value === StudyStatusesEnum.completed)?.label,
          sx: {
            ...defaultStyles,
            background: '#CEE0C7'
          }
        };  
      default:
        return {
          label: '-',
          sx: {
            ...defaultStyles,
            background: 'grey'
          }
        };
    }
  }

  if (!renderValue()) return <></>;
  if (disableStyling) return <>{renderValue()?.label}</>;

  return (
    <Box sx={renderValue().sx}>
      <Typography>{renderValue().label}</Typography>
    </Box>
  );
}

export default MStatusPlates;
