import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { MButton } from "./MButton";
import { formatDateToShow } from '../utils/utils';

interface MReportItemProps {
  title?: string;
  reportDate: Date;
  url: string;
  isLastChild?: boolean;
}

export const MReportItem = (props: MReportItemProps) => {
  const { title = 'Final Report', reportDate, url, isLastChild = false } = props;

  return (
    <>
      <Divider />
      <Box display="flex" justifyContent="space-between" py={4}>
        <Box display="flex" alignItems="center">
          <Typography sx={{ width: 160, fontSize: 18, fontWeight: 600, pl: 2 }}>{title}</Typography>
          <Typography>Generated on {formatDateToShow(reportDate)}</Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            '& > *': {
              marginLeft: 4 
            }
          }}
        >
          <Box>
            <MButton onClick={() => window.open(url, "_blank")}>View Report</MButton>
          </Box>
        </Box>
      </Box>
      {isLastChild && <Divider />}
    </>
  );
}

export default MReportItem;
