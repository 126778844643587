import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { MStatusPlates } from "../components/MStatusPlates";
import { MReportItem } from "../components/MReportItem";
import {
  formatDateToShow,
  formatDateTimeToShow,
  formatStudyType,
  formatEnrollment,
  isMCT,
  isTwoWeekHolter
} from '../utils/utils';
import {Fragment} from "react";
import UploadDMS from "./UploadDMS";

interface StudySummaryProps {
  values?: any;
  devices?: any[];
  devicesMCT?: any[];
  reports?: any[];
}

export const StudySummary = ({ values, devices = [], devicesMCT = [], reports = [] }: StudySummaryProps) => {
  const renderDeviceAndDate = () => {
    const currentStudyType = (isMCT(values.study_type) || isTwoWeekHolter(values.study_type))

    if (currentStudyType) {
      return {
        devices: devicesMCT.length ? devicesMCT.map((d: any, key: number) => (
          <span key={key} style={{ display: 'block' }}>
            {d.device_serial_number}
          </span>
        )) : '—',
        startDate: values.date_start ? formatDateTimeToShow(values.date_start) : '—'
      }
    } else if (!currentStudyType) {
      return {
        devices: devices.length ? devices.map((d: any, key: number) => (
          <span key={key} style={{ display: 'block' }}>
            {d.device_serial_number}
          </span>
        )) : '—',
        startDate: values.date_start ? formatDateTimeToShow(values.date_start) : '—'
      }
    }

    return {
      devices: '—',
      startDate: '—'
    }
  }

  const patientInfo = [
    {
      label: 'Status',
      value: <MStatusPlates disableStyling value={values.status} />
    },
    {
      label: 'Date Created',
      value: formatDateToShow(values.date_start)
    },
    {
      label: 'Recording Type',
      value: formatStudyType(values.study_type)
    },
    {
      label: 'Enrollment',
      value: formatEnrollment(values.enrollment)
    },
    {
      label: 'Device ID',
      value: renderDeviceAndDate().devices
    },
    {
      label: 'Test start time',
      value: renderDeviceAndDate().startDate
    }
  ]

  const uploadSection = (device: any) => {
    if (device.date_upload !== "None") {
      return <Typography sx={{ fontSize: 16, py: 3 }}>The files have been uploaded.</Typography>
    }

    return <UploadDMS device={device} patientIdUnique={values.patient.id_unique} />
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={7.5}>
        {
          patientInfo.map((pi, key) =>(
            <Box key={key}>
              <Typography sx={{ color: 'rgba(79, 73, 73, 0.5)', fontSize: 14, pb: 0.5 }}>{pi.label}</Typography>
              <Typography>{pi.value}</Typography>
            </Box>
          ))
        }
      </Box>
      <Box sx={{width:"100%"}}>
        {devices.length > 0 && devices[0].device_serial_number.length > 5 && (
          <>
            <Box mb={2}>
              <Typography variant="h5">Uploads</Typography>
            </Box>
            {devices && devices?.map((device, index) => (
                <Fragment key={index}>
                  {uploadSection(device)}
                </Fragment>
            ))}
          </>
        )}
      </Box>
      {
        (!!reports.length || values.report) && (
          <>
            <Typography variant="h5">Reports</Typography>
            <Box pt={3}>
              <>
                {reports && reports.map((report, key) => (
                  <MReportItem 
                    key={key}
                    title="Report"
                    reportDate={report.date_start}
                    url={report.url}
                    isLastChild={reports.length - 1 === key}
                  />
                ))}
                {values.report && (
                  <MReportItem 
                    reportDate={values.date_start}
                    url={values.report}
                    isLastChild
                  />
                )}
              </>
            </Box>
          </>
        )
      }
    </> 
  );
};

export default StudySummary;