import { PDFObject } from "react-pdfobject";
import PageHeader from "../containers/PageHeader";
import { Box, Typography } from "@mui/material";
import MTextarea from "../components/MTextarea";
import { MButton } from "../components/MButton";
import { MButtonWithConfirm } from "../components/MButtonWithConfirm";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrls } from "../routes";
import { useCallback, useEffect, useState } from "react";
import { getStudyAdmin, declineStudy, signStudy, updateStudy } from "../api/study";
import { usePrevious } from "../utils/utils";
import { v4 } from 'uuid';
import moment from "moment";
import { PermissionsE, usePermissions } from "../context/usePermissions";

export const AdminReportReview = () => {
    const { hasPermission } = usePermissions();

    const { studyId } = useParams();
    const navigate = useNavigate();
    const [key, setKey] = useState(v4());

    const [study, setStudy] = useState<any>(null);
    const [declineMessage, setDeclineMessage] = useState<string>('');
    const [comment, setComment] = useState<string>('');
    const studyLink = `${baseUrls.clientsToolsStudyBase}/${studyId}`;
    const [busy, setBusy] = useState(false);

    const prevStudy: any = usePrevious(study);

    const getStudyData = useCallback(async () => {
        if (!hasPermission!(PermissionsE["study.r_meta"])) return;
        if (studyId) {
            const { data } = await getStudyAdmin(studyId);
            setStudy(data);
            setComment(data.comment || '');
        }
    }, [])


    useEffect(() => {
        getStudyData();
    }, [])


    useEffect(() => {
        let timer: NodeJS.Timer;
        if (busy) {
            timer = setTimeout(() => {
                getStudyData();
            }, 10000);
        }

        return () => clearTimeout(timer);
    }, [busy, study, prevStudy]);

    useEffect(() => {
        if (study && prevStudy) {
            if (!moment(study.date_report_generated).isSame(prevStudy.date_report_generated)) {
                setBusy(false);
                setKey(v4());
            }
        }
    }, [study, prevStudy])

    const onDecline = async () => {
        if (!hasPermission!(PermissionsE["study.sign"])) return;
        if (studyId) await declineStudy(studyId, declineMessage).then(() => navigate(studyLink));
    }

    const onFinilizeAndSign = async () => {
        if (!hasPermission!(PermissionsE["study.sign"])) return;
        if (studyId) await signStudy(studyId).then(() => navigate(studyLink));
    }

    const updateStudyHandler = async () => {
        if (!(hasPermission!(PermissionsE["study.w_tech"]) || hasPermission!(PermissionsE["study.report_comment"]))) return;
        setBusy(true);
        await updateStudy({
            comment
        }, study?.id);
    }

    const maxCommentLength = 800;

    const declineContent = (
        <Box width="100%">
            <Box mb={3}>
                <Box mb={1} textAlign="center">
                    <Typography variant="h6">Decline details</Typography>
                </Box>
                <MTextarea
                    value={declineMessage}
                    onChange={v => setDeclineMessage(v)}
                />
            </Box>
        </Box>
    )

    const finaliseContent = (
        <Box width="100%">
            <Box mb={3} textAlign="center">
                <Typography>Do you want Finilise & Sign Report?</Typography>
            </Box>
        </Box>
    )

    if (!study) return <></>;

    return (
        <>
            <PageHeader
                title={"Review Report"}
                actions={
                    [
                        {
                            name: "Back to Study",
                            onClick: () => navigate(studyLink)
                        }
                    ]
                }
            />
            <Box display="flex" sx={{ minHeight: '70vh', height: '100%' }} key={key}>
                <Box minWidth="70%" sx={{ backgroundColor: '#ffffff' }}>
                    <PDFObject
                        url={study.report}
                        height="75vh"
                    />
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    width="100%"
                    ml={4}
                >
                    {
                        hasPermission!(PermissionsE['study.report_comment']) ? (
                            <Box>
                                <MTextarea
                                    label="Add Physician Comment"
                                    placeholder="Agree with finidings"
                                    value={comment}
                                    onChange={v => setComment(v)}
                                    maxLength={maxCommentLength}
                                />
                                <MButton
                                    busy={busy}
                                    variant="outlined"
                                    color='inherit'
                                    disabled={comment.length === maxCommentLength}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        updateStudyHandler();
                                    }}
                                >
                                    Apply comment
                                </MButton>
                            </Box>
                        ) : <></>
                    }
                    {
                        hasPermission!(PermissionsE['study.sign']) ? (
                            <Box display="flex" justifyContent="space-between">
                                <Box>
                                    <MButtonWithConfirm
                                        disabled={busy}
                                        variant="outlined"
                                        color='inherit'
                                        confirmContent={declineContent}
                                        confirmHandler={onDecline}
                                    >
                                        Decline
                                    </MButtonWithConfirm>
                                </Box>
                                <Box>
                                    <MButtonWithConfirm
                                        disabled={busy}
                                        confirmContent={finaliseContent}
                                        confirmHandler={onFinilizeAndSign}
                                    >
                                        Finalise & Sign Report
                                    </MButtonWithConfirm>
                                </Box>
                            </Box>
                        ) : <></>
                    }
                </Box>
            </Box>
        </>
    )
}

export default AdminReportReview;